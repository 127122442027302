import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import './App.css';
import Home from './pages/Home';
import Videos from './pages/Videos';
import Artists from './pages/Artists';
import KintoSol from './pages/Kintosol';
import ElChivoPage from './pages/ElChivo';
import DJPackbackPage from './pages/Payback';
import SomeonePage from './pages/Someone';
import CharlyFPage from './pages/CharlyF';
import EddySPage from './pages/EddyS';
import EmergPage from './pages/Emerg';
import SpeedyGPage from './pages/SpeedyG';
import Events from './pages/Events';
import Shows from './pages/Shows';
import Contact from './pages/Contact';

function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/artists' exact component={Artists} />
        <Route path='/kintosol' exact component={KintoSol} />
        <Route path='/Elchivo' exact component={ElChivoPage} />
        <Route path='/Payback' exact component={DJPackbackPage} />
        <Route path='/Someone' exact component={SomeonePage} />
        <Route path='/charlyf' exact component={CharlyFPage} />
        <Route path='/EddySuarez' exact component={EddySPage} />
        <Route path='/Emerg' exact component={EmergPage} />
        <Route path='/SpeedyG' exact component={SpeedyGPage} />
        <Route path='/events' exact component={Events} />
        <Route path='/videos' exact component={Videos} />
        <Route path='/shows' exact component={Shows} />
        <Route path='/contact' exact component={Contact} />
      </Switch>
    </Router>
  );
}

export default App;