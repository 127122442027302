import React from 'react';
import Footer from '../containers/Footer/Footer';
import Charlyf from '../containers/Artists/CharlyFuentes/charlyfuentes';

const CharlyFPage = () => {
  return (
    <div>
    <Charlyf />
    <Footer />
    </div>
  );
};
  
export default CharlyFPage;