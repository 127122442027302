import React, {useEffect} from 'react';
import '../../Artists/ArtistsProfile.css';
import eddys from '../../../imgs/artists-imgs/emerg-banner.jpg';
import { FaInstagram, FaFacebook, FaTwitter, FaSpotify } from 'react-icons/fa';

function EmergPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    return (
      <div className="artist-detail">
      <div className="artist-hero" style={{ backgroundImage: `url(${eddys})` }}>
        <div className="artist-hero-content">
          <div className="artist-name-container">
            <h1 className="artist-name">Emerg</h1>
          </div>
        </div>
      </div>
      <div className="artist-content">
        <div className="artist-bio">
          <h2>Biography</h2>
          <p>
          Christian Vasquez better known as Emerg, is a hip hop artist from Chicago IL,
          born and raised in the streets of Pilsen “la Calle 18”.  
          Emerg , began his hip hop career inspired by the death of his Older brother Johnny Vasquez, 
          unfortunately his older brother passed away in the year 2009 due to gang violence.
          With the mindset of vengeance, 
          He turned His negative mindset and wrote His pain in a notepad and started rapping.
          This gained the attention of many people in his neighborhood / city so he chose to continue making music. 
          Emerg has since gained many fans and the respect from Artist In the industry.          
          </p>
          <div className="artist-social">
            <a href="https://www.instagram.com/someone" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="social-icon" />
            </a>
            <a href="https://www.facebook.com/someone" target="_blank" rel="noopener noreferrer">
              <FaFacebook className="social-icon" />
            </a>
            <a href="https://twitter.com/someone" target="_blank" rel="noopener noreferrer">
              <FaTwitter className="social-icon" />
            </a>
            <a href="https://open.spotify.com/artist/your-spotify-artist-id" target="_blank" rel="noopener noreferrer">
              <FaSpotify className="social-icon" />
            </a>
          </div>
        </div>
        <div className="artist-spotify">
          <iframe
            src="https://open.spotify.com/embed/artist/4TmFnEzeZzX69ppPGPAeEW"
            width="100%"
            height="380"
            frameBorder="0"
            allowtransparency="true"
            allow="encrypted-media"
            title="Spotify Player"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default EmergPage;
