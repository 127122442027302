import React, {useEffect} from 'react';
import '../../Artists/ArtistsProfile.css';
import eddys from '../../../imgs/artists-imgs/kintosol-banner.jpg';
import { FaInstagram, FaFacebook, FaYoutube, FaSpotify } from 'react-icons/fa';

function KintosolPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="artist-detail">
      <div className="artist-hero" style={{ backgroundImage: `url(${eddys})` }}>
        <div className="artist-hero-content">
          <div className="artist-name-container">
            <h1 className="artist-name">Kinto Sol</h1>
          </div>
        </div>
      </div>
      <div className="artist-content">
        <div className="artist-bio">
          <h2>Biography</h2>
          <p>
          The Garcia brothers, Javier “DJ Payback” Garcia, Manuel “Skribe” Garcia, Eduardo "El Chivo" Garcia, along with their music,
           reflect their cross-cultural experiences as young urban Latinos. Originally from Iramuca, Mexico, 
           they moved to the United States, living in Milwaukee and Chicago, where they embraced hip-hop culture. 
           Blending their American and Mexican identities, they formed Kinto Sol and debuted with “Del Norte al Sur,”
            making a mark in Latino political protest music. Subsequent releases like “Hecho en Mexico” and “La Sangre Nunca Muere”
             emphasized pride in heritage and family loyalty. Despite industry challenges, 
             Kinto Sol’s momentum grew, leading to albums like “Los Hijos del Maiz” and “Carcel de Suenos,” 
             which addressed contemporary Latino issues. Their later albums, including “El Último Suspiro,” “Familia, Fe y Patria,” 
             and “Protegiendo el Penacho,” continued to gain recognition. Kinto Sol remains a significant force in hip-hop en español across the U.S. and Mexico.
          </p>
          <div className="artist-social">
            <a href="https://www.instagram.com/kintosolxvida/" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="social-icon" />
            </a>
            <a href="https://www.youtube.com/channel/UC89T-BR3lxaq89mqx6qPGfA" target="_blank" rel="noopener noreferrer">
              <FaYoutube className="social-icon" />
            </a>
            <a href="https://www.facebook.com/kintosolporvida" target="_blank" rel="noopener noreferrer">
              <FaFacebook className="social-icon" />
            </a>
            <a href="https://open.spotify.com/artist/0nOyxRsVicKeiBbB6zdIMu" target="_blank" rel="noopener noreferrer">
              <FaSpotify className="social-icon" />
            </a>
          </div>
        </div>
        <div className="artist-spotify">
          <iframe
            src="https://open.spotify.com/embed/artist/0nOyxRsVicKeiBbB6zdIMu?utm_source=generator&theme=0"
            width="100%"
            height="380"
            frameBorder="0"
            allowtransparency="true"
            allow="encrypted-media"
            title="Spotify Player"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default KintosolPage;
