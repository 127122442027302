import React from 'react';
import Footer from '../containers/Footer/Footer';
import Kintosol from '../containers/Artists/KintoSol/ks'

const KintoSol = () => {
  return (
    <div>
      <Kintosol/>
      <Footer />
    </div>
  );
};
  
export default KintoSol;