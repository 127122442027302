import React from 'react';
import Footer from '../containers/Footer/Footer';
import ElChivo from '../containers/Artists/ElChivo/elchivo'

const ElChivoPage = () => {
  return (
    <div>
      <ElChivo />
      <Footer />
    </div>
  );
};
  
export default ElChivoPage;