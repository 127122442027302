import React from 'react';
import Footer from '../containers/Footer/Footer';
import DJPayback from '../containers/Artists/DJPayback/djpayback'

const DJPaybackPage = () => {
  return (
    <div>
      <DJPayback />
      <Footer />
    </div>
  );
};
  
export default  DJPaybackPage ;