import React from 'react';
import '../../App.css';
import './MainHeroSection.css';

function HeroSection() {
    return (
        <div className='hero-wrap'>
            <div className='hero-container'>
                <video loading="eager" id="video1" className="full-video" src={process.env.PUBLIC_URL + '/videos/virus-intro.mp4'} autoPlay loop muted />
                <div className='text-content'>
                    <div className="out-now-container">
                        <h5 className="subheading new">New</h5>
                        <h5 className="subheading">Album</h5>
                        <div className="out-now-line"></div>
                        <h5 className="subheading">Out Now</h5>
                    </div>
                    <h1>LA ESQUINA</h1>
                    <div className="cta-buttons">
                        <button className='btns'>
                            <a href="https://open.spotify.com/track/28ulNKiXlYNIeFKAiVs9R5?autoplay=true" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}></a>Spotify</button>
                        <button className='btns'>
                            <a href="https://music.apple.com/us/album/la-esquina/1747387469?i=1747387471" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}></a>Apple Music</button>
                        <button className='btns'>
                            <a href="https://kintosol.com" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}></a>Buy</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeroSection;
