import React from 'react';
import Footer from '../containers/Footer/Footer';
import Speedyg from '../containers/Artists/SpeedyGuzman/speedyguzman'

const SpeedyGPage = () => {
  return (
    <div>
      <Speedyg />
      <Footer />
    </div>
  );
};
  
export default SpeedyGPage;