import React, { useState, useEffect } from "react";
import "./EventsSection.css";

const EventsSection = () => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Fetch the XML data using AllOrigins to bypass CORS
    fetch('https://api.allorigins.win/get?url=' + encodeURIComponent('https://kintosol.com/events/feed'))
      .then(response => response.json())
      .then(data => {
        const base64Content = data.contents.split(",")[1];
        const decodedContent = atob(base64Content);

        const parser = new DOMParser();
        const xml = parser.parseFromString(decodedContent, 'text/xml');

        const items = xml.getElementsByTagName('item');
        const parsedEvents = Array.from(items).map(item => {
          const titleNode = item.getElementsByTagName('title')[0];
          const title = titleNode ? titleNode.textContent : 'No title';

          const descriptionNode = item.getElementsByTagName('description')[0];
          const description = descriptionNode ? descriptionNode.textContent.trim() : 'No description';

          // Extract the event date from the description
          const eventDateMatch = description.match(/Event date:\s*(\w+\s+\d+)/);
          const eventDate = eventDateMatch ? new Date(eventDateMatch[0].replace('Event date: ', '')) : 'No date';

          const linkNode = item.getElementsByTagName('link')[0];
          const link = linkNode ? linkNode.textContent : '#';

          return {
            title,
            location: description,
            date: eventDate,
            link,
          };
        });

        setEvents(parsedEvents);
        setIsLoading(false); // Stop loading once data is fetched
      })
      .catch(err => {
        console.error('Error:', err);
        setIsLoading(false); // Stop loading even if there's an error
      });
  }, []);

  const formatEventDate = (date) => {
    if (date === 'No date') return date;
    return {
      day: date.getDate(),
      month: date.toLocaleString('default', { month: 'short' }).toUpperCase()
    };
  };

  return (
    <div className="event-list">
      <div className="event-image">
        <img
          src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhNWneSS9CpYwRDaaFwq0jQt0phw2t4yY-_AWqFe90vmcZUEiewVq9yIyZQ0mKgtOcbaLiCukoFNkwTOeHOLcFV2zzJg2DFnAQVJmsuJzUpUAai9hpnnu0IeWbfsqT12t0MwlIKLwjhzZQ/s1600/NEW+KS+Icon299dpi.jpg"
          alt="Event"
        />
      </div>
      <div className="event-details">
        <h2 className="event-heading">Events</h2>
        <h1>Upcoming events</h1>
        <p>Track to get concert, live stream, and tour updates.</p>
        {isLoading ? (
          <div className="loading">&nbsp;Loading events...</div> // Show a loading message or spinner
        ) : (
          <ul>
            {events.length > 0 ? (
              events.map((event, index) => {
                const formattedDate = formatEventDate(event.date);
                return (
                  <li key={index} className="event-item">
                    <div className="event-date">
                      <div className="day">{formattedDate.day}</div>
                      <div className="month">{formattedDate.month}</div>
                    </div>
                    <div className="event-info">
                      <h3 className="event-title">{event.title}</h3>
                    </div>
                    <div className="event-date">
                      <a href={event.link} target="_blank" rel="noopener noreferrer">
                        <button className="ticket-button">Tickets</button>
                      </a>
                    </div>
                  </li>
                );
              })
            ) : (
              <p>No upcoming events found.</p>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default EventsSection;