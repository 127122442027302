import React from 'react';
import Footer from '../containers/Footer/Footer';
import Emerg from '../containers/Artists/Emerg/emerg'

const EmergPage = () => {
  return (
    <div>
      <Emerg />
      <Footer />
    </div>
  );
};
  
export default EmergPage;