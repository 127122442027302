import React, {useEffect} from 'react';
import '../../Artists/ArtistsProfile.css';
import elchivo from '../../../imgs/artists-imgs/elchivo-banner.jpg';
import { FaInstagram, FaFacebook, FaYoutube, FaSpotify } from 'react-icons/fa';

function ElChivoPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="artist-detail">
      <div className="artist-hero" style={{ backgroundImage: `url(${elchivo})` }}>
        <div className="artist-hero-content">
          <div className="artist-name-container">
            <h1 className="artist-name">El Chivo</h1>
          </div>
        </div>
      </div>
      <div className="artist-content">
        <div className="artist-bio">
          <h2>Biography</h2>
          <p>
          Eduardo “El Chivo” Garcia, from Iramuco, Guanajuato, is a Mexican rapper performing solo and with his brothers and Skribe.
          He moved to Milwaukee at 13 and got into rap through his older brother, DJ Payback. As a key member of the Latino rap group Virus Enterprises, 
          he contributed to albums like Kinto Sol (2000) and La Sangre Nunca Muere (2005). 
          El Chivo debuted solo with Wachate con los Topes (2002) and followed with Si Ladran No Muerden!! (2004) and Cicatrices (2008)..       
           </p>
          <div className="artist-social">
            <a href="https://www.instagram.com/elchivo_kintosol?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="social-icon" />
            </a>
            <a href="https://www.youtube.com/channel/UC89T-BR3lxaq89mqx6qPGfA" target="_blank" rel="noopener noreferrer">
              <FaYoutube className="social-icon" />
            </a>
            <a href="https://www.facebook.com/elchivoks" target="_blank" rel="noopener noreferrer">
              <FaFacebook className="social-icon" />
            </a>
            <a href="https://open.spotify.com/artist/69SY9YEb04oDqLvN0xU9i7" target="_blank" rel="noopener noreferrer">
              <FaSpotify className="social-icon" />
            </a>
          </div>
        </div>
        <div className="artist-spotify">
          <iframe
            src="https://open.spotify.com/embed/artist/69SY9YEb04oDqLvN0xU9i7?utm_source=generator"
            width="100%"
            height="380"
            frameBorder="0"
            allowtransparency="true"
            allow="encrypted-media"
            title="Spotify Player"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default ElChivoPage;
