import React, {useEffect} from 'react';
import '../../Artists/ArtistsProfile.css';
import eddys from '../../../imgs/artists-imgs/eddys-banner.jpg';
import { FaInstagram, FaFacebook, FaTiktok, FaSpotify } from 'react-icons/fa';

function EddySPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="artist-detail">
      <div className="artist-hero" style={{ backgroundImage: `url(${eddys})` }}>
        <div className="artist-hero-content">
          <div className="artist-name-container">
            <h1 className="artist-name">Eddy Suarez</h1>
          </div>
        </div>
      </div>
      <div className="artist-content">
        <div className="artist-bio">
          <h2>Biography</h2>
          <p>
          Andrick Eduardo Suárez Pérez Compositor y cantante de canciones y corridos, Eddy Suarez y LDS, Bien puesta la bandera.       
           </p>
          <div className="artist-social">
            <a href="https://www.instagram.com/_eddysuarez/" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="social-icon" />
            </a>
            <a href="https://www.facebook.com/profile.php?id=100063743365998" target="_blank" rel="noopener noreferrer">
              <FaFacebook className="social-icon" />
            </a>
            <a href="https://www.tiktok.com/@eddy.suarez" target="_blank" rel="noopener noreferrer">
              <FaTiktok className="social-icon" />
            </a>
            <a href="https://open.spotify.com/embed/artist/0kxpgoZ4fwPJLkWpXCQiWd" target="_blank" rel="noopener noreferrer">
              <FaSpotify className="social-icon" />
            </a>
          </div>
        </div>
        <div className="artist-spotify">
          <iframe
            src="https://open.spotify.com/embed/artist/0kxpgoZ4fwPJLkWpXCQiWd"
            width="100%"
            height="380"
            frameBorder="0"
            allowtransparency="true"
            allow="encrypted-media"
            title="Spotify Player"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default EddySPage;
