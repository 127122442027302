import React from 'react';
import EventsSection from '../containers/Events/EventsSection';
import Footer from '../containers/Footer/Footer';
  
const Events = () => {
  return (
    <div>
      <EventsSection />
      <Footer />
    </div>
  );
};
  
export default Events;