import React, {useEffect} from 'react';
import '../../Artists/ArtistsProfile.css';
import eddys from '../../../imgs/artists-imgs/speedyg-banner.jpg';
import { FaInstagram, FaFacebook, FaYoutube, FaSpotify } from 'react-icons/fa';

function SpeedyGPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="artist-detail">
      <div className="artist-hero" style={{ backgroundImage: `url(${eddys})` }}>
        <div className="artist-hero-content">
          <div className="artist-name-container">
            <h1 className="artist-name">Speedy Guzman</h1>
          </div>
        </div>
      </div>
      <div className="artist-content">
        <div className="artist-bio">
          <h2>Biography</h2>
          <p>
                
          </p>
          <div className="artist-social">
              <a href="https://www.instagram.com/someonesm1" target="_blank" rel="noopener noreferrer">
                <FaInstagram className="social-icon" />
              </a>
              <a href="https://www.youtube.com/channel/UCGg-FScnE1ErVi51Qv-3h9A" target="_blank" rel="noopener noreferrer">
                <FaYoutube className="social-icon" />
              </a>
              <a href="https://www.facebook.com/someonesm1/" target="_blank" rel="noopener noreferrer">
                <FaFacebook className="social-icon" />
              </a>
              <a href="https://open.spotify.com/artist/3WyJImKjA4PH3EXWIWBokJ?autoplay=true" target="_blank" rel="noopener noreferrer">
                <FaSpotify className="social-icon" />
              </a>
          </div>
        </div>
        <div className="artist-spotify">
          <iframe
            src="https://open.spotify.com/embed/artist/31d63AfxgSHrIS06FIcy9X?utm_source=generator&theme=0"
            width="100%"
            height="380"
            frameBorder="0"
            allowtransparency="true"
            allow="encrypted-media"
            title="Spotify Player"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default SpeedyGPage;
