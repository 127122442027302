import React, {useEffect} from 'react';
import '../../Artists/ArtistsProfile.css';
import djpayback from '../../../imgs/artists-imgs/djpayback-banner.jpg';
import { FaInstagram, FaFacebook, FaYoutube, FaSpotify } from 'react-icons/fa';

function DJPackbackPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="artist-detail">
      <div className="artist-hero" style={{ backgroundImage: `url(${djpayback})` }}>
        <div className="artist-hero-content">
          <div className="artist-name-container">
            <h1 className="artist-name">DJ Payback Garcia</h1>
          </div>
        </div>
      </div>
      <div className="artist-content">
        <div className="artist-bio">
          <h2>Biography</h2>
          <p>
        El momento que escuchas a la musica de Payback, pega al esencia de tu alma. La secuencia de la musica esta
        llena de imaginacion y creatividad que te hace levantar y bailar. La musica que el comparte con el escuchador
        no tiene limites o edad y atrae a las masas. Payback ha dominado lo que ningun otro DJ ha hecho, la abilidad
        de captivar con su musica. Su musica resona atraves de America y en otras partes del mundo. Su abilidad de
        mezclar diferentes estilos de musica ha creado una nueva era para todas generaciones y Payback ha creado su
        propia marca musical. Payback se enorgullece del arte que el crea y solo lo mejor es lanzado para el placer del
        mundo. Tiene varias mezclas “subterraneas” que lo han hecho popular en las calles. Payback ayudo a lanzar
        las carreras de artistas conocidos como “El Chivo” . Ademas, Payback ha producido musica para el grupo de
        hip-hop popular “Kinto Sol”, siendo miembro esencial desde el comienzo, su talento y habilidades sirviendo
        como llaves al exito del grupo. Payback es una inspiracion para muchos y ha creado sus propios seguidores en
        esta arena competitiva.    
           </p>
          <div className="artist-social">
            <a href="https://www.instagram.com/djpaybackgarcia/" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="social-icon" />
            </a>
            <a href="https://www.facebook.com/djpaybackgarciaks/" target="_blank" rel="noopener noreferrer">
              <FaFacebook className="social-icon" />
            </a>
            <a href="https://www.youtube.com/@DjPaybackGarcia/videos" target="_blank" rel="noopener noreferrer">
              <FaYoutube className="social-icon" />
            </a>
            <a href="https://open.spotify.com/artist/3bePxeph4fumKKuo7KHqVF" target="_blank" rel="noopener noreferrer">
              <FaSpotify className="social-icon" />
            </a>
          </div>
        </div>
        <div className="artist-spotify">
          <iframe
            src="https://open.spotify.com/embed/artist/3bePxeph4fumKKuo7KHqVF?utm_source=generator"
            width="100%"
            height="380"
            frameBorder="0"
            allowtransparency="true"
            allow="encrypted-media"
            title="Spotify Player"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default DJPackbackPage;
