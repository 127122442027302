import '../App.css';
import HeroSection from '../containers/Hero/MainHeroSection';
import MobileHeroSection from '../containers/Hero/MobileHeroSection';

import React, { useEffect}  from 'react';
import Footer from '../containers/Footer/Footer';
import EventsSection from '../containers/Events/EventsSection';
import Artists from '../containers/Artists/Artists';


function Home() {

    useEffect(() => {

        // const previousOverflow = document.body.style.overflow;
    
        // Disable scrolling
        // document.body.style.overflow = 'hidden';
    
        return () => {
          // Re-enable scrolling when the component is unmounted
          // document.body.style.overflow = previousOverflow;
        };
      }, []);
    
      return (
        <>
          {window.innerWidth > 800 ? <HeroSection />  : <MobileHeroSection /> }
          <Artists />
          <EventsSection />
          {/* <Videos /> */}
          <Footer />
        </>
        
      );
    }

export default Home;




