import React from "react";
import { Link } from "react-router-dom";
import "./Artists.css";
import kintosolImage from '../../imgs/artists-imgs/kintosol.jpg';
import elchivo from '../../imgs/artists-imgs/elchivo.jpg'
import djpayback from '../../imgs/artists-imgs/djpayback.jpg'
import someone from '../../imgs/artists-imgs/sm1.jpg';
import charly from '../../imgs/artists-imgs/charly-fuentes.jpg';
import eddysuarez from '../../imgs/artists-imgs/eddysuarez.jpg';
import emerg from '../../imgs/artists-imgs/emerg.jpg';
import nopicture from '../../imgs/artists-imgs/speedyg.jpg';

const artistsData = [
  { name: "Kinto Sol", image: kintosolImage, link: "/kintosol" },
  { name: "El Chivo", image: elchivo, link: "/elchivo" },
  { name: "Dj Payback", image: djpayback, link: "/payback" },
  { name: "SomeOne SM1", image: someone, link: "/Someone" },
  { name: "Charly Fuentes", image: charly, link: "/charlyf" },
  { name: "Eddy Suarez", image: eddysuarez, link: "/EddySuarez" },
  { name: "Emerg", image: emerg, link: "/Emerg" },
  { name: "Speedy Guzman", image: nopicture, link: "/SpeedyG" },
];

function Artists() {
  const handleArtistClick = (artist) => {
    console.log(`Clicked on artist: ${artist.name}`);
  };

  return (
    <div className="artists-section">
      <h2 className="artists-heading">Artists</h2>
      <div className="artists-grid">
        {artistsData.map((artist, index) => (
          <div key={index} className="artist-card" onClick={() => handleArtistClick(artist)}>
            {artist.link.startsWith('http') ? (
              <a href={artist.link} target="_blank" rel="noopener noreferrer">
                <img src={artist.image} alt={artist.name} className="artist-image" />
                <div className="artist-name">{artist.name}</div>
              </a>
            ) : (
              <Link to={artist.link}>
                <img src={artist.image} alt={artist.name} className="artist-image" />
                <div className="artist-name">{artist.name}</div>
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Artists;