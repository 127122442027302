import React, { useState, useEffect } from 'react';
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setClick(false); // Close the mobile menu on resize if width is greater than 768px
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="navbar">
      <div className="navbar-left">
        <img src={process.env.PUBLIC_URL + '/assets/virus.png'} alt="Logo" className="logo-image" />
      </div>
      <div className="navbar-center">
        <a href="/" onClick={closeMobileMenu}>HOME</a>
        <a href="/artists" onClick={closeMobileMenu}>ARTISTS</a>
        <a href="/videos" onClick={closeMobileMenu}>VIDEOS</a>
        <a href="http://kintosol.com" target="_blank" rel="noopener noreferrer" onClick={closeMobileMenu}>MERCH</a>
        <a href="/shows" onClick={closeMobileMenu}>SHOWS</a>
        <a href="/contact" onClick={closeMobileMenu}>CONTACT</a>
      </div>
      <div className="navbar-right">
        <a href="https://www.facebook.com/virusenterprises" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" /></a>
        <a href="https://www.instagram.com/virusenterprisesofficial/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /></a>
        <a href="https://www.youtube.com/@VirusMusicTVEnt" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube" /></a>
      </div>
      {/* Mobile Navbar */}
      <div className="navbar-mobile">
        <img src={process.env.PUBLIC_URL + '/assets/virus.png'} alt="Logo" className="logo-image" />
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
      </div>
      <div className={`navbar-menu ${click ? 'open' : ''}`}>
        <a href="/" onClick={closeMobileMenu}>HOME</a>
        <a href="/artists" onClick={closeMobileMenu}>ARTISTS</a>
        <a href="/videos" onClick={closeMobileMenu}>VIDEOS</a>
        <a href="http://kintosol.com" target="_blank" rel="noopener noreferrer" onClick={closeMobileMenu}>MERCH</a>
        <a href="/shows" onClick={closeMobileMenu}>SHOWS</a>
        <a href="/contact" onClick={closeMobileMenu}>CONTACT</a>
        <div className="social-icons">
          <a href="https://www.facebook.com/virusenterprises" target="_blank" rel="noopener noreferrer" onClick={closeMobileMenu}><i className="fab fa-facebook-f" /></a>
          <a href="https://www.instagram.com/virusenterprisesofficial/" target="_blank" rel="noopener noreferrer" onClick={closeMobileMenu}><i className="fab fa-instagram" /></a>
          <a href="https://www.youtube.com/@VirusMusicTVEnt" target="_blank" rel="noopener noreferrer" onClick={closeMobileMenu}><i className="fab fa-youtube" /></a>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
