import React, {useEffect} from 'react';
import '../../Artists/ArtistsProfile.css';
import someoneImage from '../../../imgs/artists-imgs/sm1-banner.jpeg';
import { FaInstagram, FaFacebook, FaYoutube, FaSpotify } from 'react-icons/fa';

function SomeonePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="artist-detail">
      <div className="artist-hero" style={{ backgroundImage: `url(${someoneImage})` }}>
        <div className="artist-hero-content">
          <div className="artist-name-container">
            <h1 className="artist-name">SomeOne SM1</h1>
          </div>
        </div>
      </div>
      <div className="artist-content">
        <div className="artist-bio">
          <h2>Biography</h2>
          <p>
          SomeOne SM1 is a Mexican American rapper, songwriter, and music producer. 
          He made his debut in the music industry by featuring on a track with the Billboard Award-winning group Kinto Sol. 
          He co-produced the background music for the Julio Cesar Chavez Jr. 
          fight on HBO and contributed to the soundtracks for the show “The Shield” on FX Network and the series “Border Wars” on the National Geographic Channel. 
          SomeOne SM1 has worked with various renowned rappers, including Aczino, the “Red Bull Batalla de los Gallos” Freestyle Tournament Champion, as well as C-Kan from Mexico,
           NK Profeta from Venezuela, Tabernario, Tankeone, Eptos Uno, Manotas, and Juan Gotti of Dope House Records. 
           With four albums already released, he is set to launch his fifth studio album, “A Mexican Me,” in 2020.          
           </p>
          <div className="artist-social">
            <a href="https://www.instagram.com/someonesm1" target="_blank" rel="noopener noreferrer">
                <FaInstagram className="social-icon" />
              </a>
              <a href="https://www.youtube.com/channel/UCGg-FScnE1ErVi51Qv-3h9A" target="_blank" rel="noopener noreferrer">
                <FaYoutube className="social-icon" />
              </a>
              <a href="https://www.facebook.com/someonesm1/" target="_blank" rel="noopener noreferrer">
                <FaFacebook className="social-icon" />
              </a>
              <a href="https://open.spotify.com/artist/3WyJImKjA4PH3EXWIWBokJ?autoplay=true" target="_blank" rel="noopener noreferrer">
                <FaSpotify className="social-icon" />
              </a>
          </div>
        </div>
        <div className="artist-spotify">
          <iframe
            src="https://open.spotify.com/embed/artist/3WyJImKjA4PH3EXWIWBokJ?utm_source=generator&theme=0"
            width="100%"
            height="380"
            frameBorder="0"
            allowtransparency="true"
            allow="encrypted-media"
            title="Spotify Player"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default SomeonePage;