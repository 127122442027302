import React from 'react';
import Footer from '../containers/Footer/Footer';
import EddyS from '../containers/Artists/EddySuarez/eddysuarez'

const EddySPage = () => {
  return (
    <div>
      <EddyS />
      <Footer />
    </div>
  );
};
  
export default EddySPage;