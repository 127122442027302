import React, { useEffect } from 'react';
import '../../Artists/ArtistsProfile.css';
import charly from '../../../imgs/artists-imgs/charleyfuentes-banner.jpeg';
import { FaInstagram, FaFacebook, FaYoutube, FaSpotify } from 'react-icons/fa';

function CharlyFPage() {
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="artist-detail">
    <div className="artist-hero" style={{ backgroundImage: `url(${charly})` }}>
      <div className="artist-hero-content">
        <div className="artist-name-container">
          <h1 className="artist-name">Charly Fuentes</h1>
        </div>
      </div>
    </div>
    <div className="artist-content">
      <div className="artist-bio">
        <h2>Biography</h2>
        <p>
        Charly Fuentes is a Mexican singer, rapper and songwriter from La Piedad, Michoacan, Mexico.        
         </p>
        <div className="artist-social">
        <a href="https://www.instagram.com/charlyfuentesmx" target="_blank" rel="noopener noreferrer">
                <FaInstagram className="social-icon" />
              </a>
              <a href="https://www.youtube.com/channel/UCGg-FScnE1ErVi51Qv-3h9A" target="_blank" rel="noopener noreferrer">
                <FaYoutube className="social-icon" />
              </a>
              <a href="https://www.facebook.com/CharlyFuentesMX" target="_blank" rel="noopener noreferrer">
                <FaFacebook className="social-icon" />
              </a>
              <a href="https://open.spotify.com/artist/3ijEuTyWd1NJJ9v8pEaNyi?si=uYSyzO2VRdGg5sWfOCbVuw" target="_blank" rel="noopener noreferrer">
                <FaSpotify className="social-icon" />
              </a>
        </div>
      </div>
      <div className="artist-spotify">
        <iframe
          src="https://open.spotify.com/embed/artist/3ijEuTyWd1NJJ9v8pEaNyi?utm_source=generator&theme=0"
          width="100%"
          height="380"
          frameBorder="0"
          allowtransparency="true"
          allow="encrypted-media"
          title="Spotify Player"
        ></iframe>
      </div>
    </div>
  </div>
  );
}

export default CharlyFPage;
